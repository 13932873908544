@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

:root {
  --black: #1e272e;
  --blue: #0097e6;
}

a {
  text-decoration: none;
}

.header {
  height: 6rem;
  width: 100%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  line-height: 6rem;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  z-index: 111;
}

.header a {
  color: rgba(17, 17, 17, 0.863);
}

.header a:hover {
  color: #0097e6;
  color: var(--blue);
}

.header .logo a {
  padding-left: 3rem;
  font-weight: bold;
  color: #0097e6;
  color: var(--blue);
}

.logo {
  width: 50%;
}

.links {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.links i {
  display: none;
}
.nav_active {
  color: #111 !important;
  font-weight: bold;
  position: relative;
}
.nav_active i {
  color: #0097e6 !important;
  color: var(--blue) !important;
}

.nav_active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background-color: #0097e6;
  bottom: 1.2rem;
  left: 0;
}

@media (max-width: 700px) {
  html {
    font-size: 58.2%;
  }
  .header {
    background-color: rgba(17, 17, 17, 0.966);
  }
  .logo span {
    color: #fff !important;
    letter-spacing: 0.1rem;
  }
  .header .logo {
    width: 100%;
    font-weight: bold;
    font-size: 2.5rem;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
  }
  .links {
    height: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(17, 17, 17, 0.966);
    z-index: 1000;
  }
  .links i {
    display: block;
    color: #fff;
    font-size: 2.3rem;
  }
  .links span {
    display: none;
  }
  .nav_active::after {
    display: none;
  }
}

button{
    padding: 5px 25px;
    font-size: 1.8rem;
    margin: 1rem 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    box-shadow: 3px 3px 3px rgba(68, 68, 68, 0.6);
    border-radius: 5px;
    color: rgb(24, 21, 21);
    display: block;
  

  }
  @media (max-width:700px){
    button{
      margin: 2rem auto;
    }
  }



*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
  }
  :root{
      --main-black : #1e272ebb;
      --main-blue : #0097e6; 
  }
  html{
      font-size: 62.5%;
  }
 
  .error_center{
    -webkit-user-select:none;
        -ms-user-select:none;
            user-select:none;
    font-family: 'Raleway',sans-serif;
    height: calc(100vh - 6rem);
    width: 90%;
    margin:  auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    }
 
  .error_center p{
      font-size: 1.5rem;
      text-align: center;
  }
  .error_center h2{
      font-weight: 400;
      font-size: 2.5rem;
      margin: 1.5rem 0;
      max-width: 60rem;
      text-align: center;
      line-height: 3.5rem;
  }
  .error_logo{
      font-size: 15rem;
      -webkit-animation: scale 1s infinite alternate;
              animation: scale 1s infinite alternate; 
  }
  @-webkit-keyframes scale {
      0%{
          transform: scale(1);
      }
      100%{
        transform: scale(1.02);
    }
  }
  @keyframes scale {
      0%{
          transform: scale(1);
      }
      100%{
        transform: scale(1.02);
    }
  }
  .error_logo .second{
      color: #0097e6;
      color: var(--main-blue);
      
  }
  @media (max-width:768px){
    html{
        font-size: 58.2%;
    }
  }

  @media (min-width:2000px){
    html{
        font-size: 90.2%;
    }
  }
  .second{
    position: relative;
  }

  .second::after{
    content: '.. ';
    position: absolute;
    top: -4rem;
    left: 1.7rem;
  }
html {
  font-size: 62.5%;
}
.loder {
  height: calc(100vh - 6rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loder img {
  height: 20rem;
  -webkit-user-drag: none;
}

