button{
    padding: 5px 25px;
    font-size: 1.8rem;
    margin: 1rem 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    box-shadow: 3px 3px 3px rgba(68, 68, 68, 0.6);
    border-radius: 5px;
    color: rgb(24, 21, 21);
    display: block;
  

  }
  @media (max-width:700px){
    button{
      margin: 2rem auto;
    }
  }


