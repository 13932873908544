@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400&display=swap');
*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
  }
  :root{
      --main-black : #1e272ebb;
      --main-blue : #0097e6; 
  }
  html{
      font-size: 62.5%;
  }
 
  .error_center{
    user-select:none;
    font-family: 'Raleway',sans-serif;
    height: calc(100vh - 6rem);
    width: 90%;
    margin:  auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    }
 
  .error_center p{
      font-size: 1.5rem;
      text-align: center;
  }
  .error_center h2{
      font-weight: 400;
      font-size: 2.5rem;
      margin: 1.5rem 0;
      max-width: 60rem;
      text-align: center;
      line-height: 3.5rem;
  }
  .error_logo{
      font-size: 15rem;
      animation: scale 1s infinite alternate; 
  }
  @keyframes scale {
      0%{
          transform: scale(1);
      }
      100%{
        transform: scale(1.02);
    }
  }
  .error_logo .second{
      color: var(--main-blue);
      
  }
  @media (max-width:768px){
    html{
        font-size: 58.2%;
    }
  }

  @media (min-width:2000px){
    html{
        font-size: 90.2%;
    }
  }
  .second{
    position: relative;
  }

  .second::after{
    content: '.. ';
    position: absolute;
    top: -4rem;
    left: 1.7rem;
  }